<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">运行监管</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">问题分类</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom: 5px">
            <div title="问题" class="searchboxItem ci-full">
              <span class="itemLabel">问题:</span>
              <el-input
                v-model="explainQuestion"
                type="text"
                size="small"
                placeholder="请输入问题"
                clearable
              />
            </div>

            <div title="是否发布" class="searchboxItem ci-full">
              <span class="itemLabel">是否发布:</span>
              <el-select
                v-model="publish"
                placeholder="请选择"
                size="small"
                clearable
              >
                <el-option
                  v-for="item in publishStateList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>

            <div title="分类" class="searchboxItem ci-full">
              <span class="itemLabel">分类:</span>
              <el-select
                v-model="explainType"
                placeholder="请选择"
                size="small"
                clearable
              >
                <el-option
                  v-for="item in explainTypeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>

            <div title="反馈时间" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width: 7rem">反馈时间:</span>
              <el-date-picker
                v-model="theClassTime"
                size="small"
                type="daterange"
                style="width: 5rem"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                placeholder="选择反馈时间"
                value="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </div>

            <el-button
              class="bgc-bv"
              style="margin: 0 10px"
              round
              @click="getData()"
              >查询</el-button
            >

            <el-button
              class="bgc-bv"
              style="margin: 0 10px"
              round
              @click="onShow()"
              >新增</el-button
            >
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
              @cell-mouse-enter="M_Enter"
              @cell-mouse-leave="m_leave"
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
                width="100px"
                fixed
              />
              <el-table-column
                label="问题"
                align="center"
                prop="explainQuestion"
                show-overflow-tooltip
                fixed
              />
              <!-- <el-table-column
                label="解决方法"
                align="left"
                prop="explainAnswer"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <span v-html="scope.row.explainAnswer"></span>
                </template>
              </el-table-column> -->

              <el-table-column
                label="是否发布"
                align="center"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <el-switch
                    v-model="scope.row.publish"
                    active-color="#13ce66"
                    @change="
                      (val) => {
                        publishType(val, scope.row);
                      }
                    "
                  ></el-switch>
                  <span style="vertical-align: middle; margin-left: 4px">
                    {{ scope.row.publish ? "已发布" : "未发布" }}
                  </span>
                </template>
              </el-table-column>

              <el-table-column
                label="热门问题"
                align="center"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <el-switch
                    v-model="scope.row.hot"
                    active-color="#13ce66"
                    @change="
                      (val) => {
                        hotType(val, scope.row);
                      }
                    "
                  >
                  </el-switch>
                  <span style="vertical-align: middle; margin-left: 4px">{{
                    scope.row.hot ? "是" : "否"
                  }}</span>
                </template>
              </el-table-column>

              <el-table-column
                label="排序"
                align="center"
                width="130"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <div v-if="!scope.row.orderShow">
                    <span> {{ scope.row.sort }}</span>
                    <i
                      class="el-icon-edit"
                      v-show="scope.row.showInput"
                      @click="changeOrder(scope.row)"
                    ></i>
                  </div>
                  <el-input
                    v-else
                    size="mini"
                    style="width: 100%"
                    v-model="scope.row.sort"
                      oninput="value=value.replace(/[^\d]/g,'')"
                    placeholder="请输入"
                    @change="
                      (val) => {
                        sortModify(val, scope.row);
                      }
                    "
                  ></el-input>

                  <!-- el-icon-edit -->
                </template>
              </el-table-column>

              <el-table-column
                label="创建时间"
                align="center"
                show-overflow-tooltip
                prop="createTime"
              >
                <template slot-scope="scope">{{
                  scope.row.createTime || "--"
                }}</template>
              </el-table-column>

              <el-table-column
                label="有用(人次)"
                align="center"
                prop="usefulNum"
                width="130"
                show-overflow-tooltip
              />

              <el-table-column
                label="没用(人次)"
                align="center"
                prop="uselessNum"
                width="130"
                show-overflow-tooltip
              />

              <el-table-column label="操作" align="center" fixed="right">
                <div slot-scope="scope" class="flexcc">
                  <el-button
                    type="text"
                    style="padding: 0px 5px"
                    size="mini"
                    @click="handleLook(scope.row)"
                  >
                    查看详情
                  </el-button>
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>

    <!-- 新增讲师-->
    <el-dialog
      :title="dialogTitle"
      :visible.sync="showLecturer"
      width="30%"
      top="2%"
      :center="true"
      :before-close="doClose"
      :close-on-click-modal="false"
    >
      <component
        v-if="showLecturer"
        :is="componentName"
        :explainId="explainId"
        @close="doClose"
      ></component>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";

export default {
  name: "questionClassification",
  components: {
    Operation: (resolve) => {
      require(["./operation.vue"], resolve);
    },
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      explainQuestion: "", // 问题
      publish: "", // 是否发布
      // 发布的选项
      publishStateList: [
        {
          value: "",
          label: "全部",
        },
        {
          value: "1",
          label: "已发布",
        },
        {
          value: "2",
          label: "未发布",
        },
      ],
      explainType: "", // 分类
      explainTypeList: [], // 分类选项
      theClassTime: "", // 反馈时间

      tableData: [], // 列表数据
      pageSize: 10, // 每页条数
      showLecturer: false, // 新增弹窗显示状态
      dialogTitle: "", // 弹窗标题
      explainId: "", // 问题分类id
      componentName: "",
    };
  },
  mounted() {
    this.getClassification();
  },
  computed: {},
  destroyed() {
    
  },
  methods: {
    // 点击笔
    changeOrder(row) {
      row.orderShow = !row.orderShow;
    },
    // 鼠标进入事件
    M_Enter(row, column, cell, event) {
      if (column.label == "排序") {
        row.showInput = true;
      }
    },
    // 鼠标离开事件
    m_leave(row, column, cell, event) {
      row.showInput = false;
    },
    handleListDataBack(data) {
      this.tableData = [];
      data.map((el) => {
        el.showInput = false;
        el.orderShow = false;
        this.tableData.push(el);
      });
    },
    // 关闭
    doClose() {
      this.showLecturer = false;
      this.explainId = "";
      this.getData(-1);
    },

    // 查看详情
    handleLook(row) {
      this.showLecturer = true;
      this.componentName = "Operation";
      // this.formDisabled = true;
      this.dialogTitle = "查看问题分类";
      console.log(row.explainId);
      this.explainId = row.explainId;
    },
    // 修改排序
    sortModify(state, row) {
      console.log(state);
      ;
      if (state < 0) {
        state = 0;
      }
      let data = {
        explainId: row.explainId,
        hot: row.hot,
        publish: row.publish,
        sort: state,
      };

      this.$post("/sys/feedback/explain/sort", data).then((res) => {
        console.log("res: ", res);
        if (res.status == "0") {
          this.$message({
            type: "success",
            message: "修改成功",
          });
          this.getData(-1);
        }
      });
    },
    // 修改热门状态
    hotType(state, row) {
      let data = {
        explainId: row.explainId,
        hot: state,
        publish: row.publish,
        sort: row.sort,
      };

      this.$post("/sys/feedback/explain/hot", data).then((res) => {
        console.log("res: ", res);
        if (res.status == "0") {
          this.$message({
            type: "success",
            message: "修改成功",
          });
          this.getData(-1);
        }
      });
    },
    // 修改发布状态
    publishType(state, row) {
      let data = {
        explainId: row.explainId,
        hot: row.hot,
        publish: state,
        sort: row.sort,
      };

      this.$post("/sys/feedback/explain/publish", data).then((res) => {
        console.log("res: ", res);
        if (res.status == "0") {
          this.$message({
            type: "success",
            message: "修改成功",
          });
          this.getData(-1);
        }
      });
    },

    // 获取分类数据
    getClassification() {
      let list = this.$setDictionary("FEEDBACKEXPLAINTYPE", "list");
      for (const key in list) {
        const element = list[key];
        this.explainTypeList.push({
          value: key,
          label: element,
        });
      }
    },
    // 显示弹窗
    onShow() {
      this.showLecturer = true;
      this.dialogTitle = "新增问题分类";
      this.componentName = "Operation";
    },
    // 获取问题分类列表
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };

      if (this.explainQuestion) {
        params.explainQuestion = this.explainQuestion;
      }

      if (this.publish) {
        this.publish == "1"
          ? (params.publish = true)
          : (params.publish = false);
      }

      if (this.explainType) {
        params.explainType = this.explainType;
      }

      if (this.theClassTime) {
        params.createTimeStart = this.theClassTime[0];
        params.createTimeEnd = this.theClassTime[1];
      }

      this.doFetch({
        url: "/sys/feedback/explain/pageList",
        params,
        pageNum,
      });
    },
      getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + .675) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    }
  },
};
</script>
<style lang="less">
.addlist {
  .el-icon-plus:before {
    content: "\e6d9";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
<style lang="less">
.seeArea {
  .el-radio-group {
    display: flex;
    flex-direction: column;
    .el-radio :last-child {
      margin-top: 1rem;
    }
  }
}

.searchbox {
  padding: 0;
}
.cascader {
  position: relative;
  width: 100%;
  z-index: 10;
}
.tp {
  width: 50%;
  float: left;
  .img-el-upload {
    width: 63%;
    float: left;
    margin-right: 2%;
  }
  .tswz {
    float: left;
    font-size: 12px;
    line-height: 25px;
    color: #909399;
    .t1 {
      font-size: 14px;
      line-height: 40px;
      color: #606266;
    }
  }
}
.form-box {
  padding: 10px 20px;
  .form {
    min-width: 600px;
    padding: 20px;
  }
  .form-bg {
    background-color: rgb(247, 248, 253);
  }
}
</style>
<style lang="less" scoped>
.bannerBox .el-form-item__content .hide /deep/.el-upload--picture-card {
  display: none;
}
</style>
